import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {payLoadEnum } from '../enum/StepEnum';
import CustomizedSteppers from "../linearSteps/CustomizedSteppers";
import "./Template.css";
import Step1PayloadList from "./PayloadList";
import { useCardContext } from "../context/CardContext";
import { events } from "../../data/PayloadData";
import { useState } from "react";
import { Step2SelectVariables } from "../Step2SelectVariables";
import { transformPayloadToData } from "../../util/transformPayloadToData";



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const SelectPayload = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { payLoadData } = useCardContext();

  return (
    <div className="template_card">
      <div className="fixed-header">
        <CustomizedSteppers
          activeStep={activeStep}
          setActiveStep={setActiveStep} 
        />
      </div>
      <div className="template_content">
                {payLoadData === payLoadEnum.INITIAL_PAYLOAD && <Step1PayloadList />}
                {events.map(
                  (event, index) =>
                    payLoadData === event.name && (
                      <Step2SelectVariables key={index} payload={transformPayloadToData(event.payload)} />
                    )
                )}
      </div>
    </div>
  );
};

export default SelectPayload;
