import LabelImportantSharpIcon from '@mui/icons-material/LabelImportantSharp';
import { Button, Container, Divider, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { PayloadItem } from "../models/PayloadItem";
import { CustomizedTreeView } from "./CustomizedTreeView";
import { ExamplePayloadContent } from "./ExamplePayloadContent";
import CustomCard from "./category/CustomCard";
import { useCardContext } from "./context/CardContext";



export const Step2SelectVariables: React.FC<{ payload: PayloadItem[] }> = ({ payload }) => {

  const { selectedPayLoadItem, payloadVariables } = useCardContext()
  const [selectedVariableName, setSelectedVariableName] = useState<string>()
  const theme = useTheme()

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" color="primary">Create Variables</Typography>
      <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: theme.palette.grey[100], border: `1px solid ${theme.palette.grey[800]}` }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div
            style={{
              flexBasis: "300px",
              flexGrow: '1',
              backgroundColor: theme.palette.background.default,
              margin: '20px',
              padding: '10px',
              border: `1px solid ${theme.palette.grey[400]}`
            }}>
            <CustomizedTreeView payload={payload} />
          </div>
          <div
            style={{
              flexGrow: '1000',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: theme.palette.background.default,
              margin: '20px',
              padding: '20px',
              border: `1px solid ${theme.palette.grey[400]}`
            }}>
            {!selectedPayLoadItem &&
              <Typography variant="overline" color="primary">Click on an attribute from the left side tree-view to create a variable</Typography>
            }
            {selectedPayLoadItem &&
              <div
                style={{
                  width: '100%',
                  height: '100%',
                }}>
                <CustomCard dataItem={selectedPayLoadItem} />
              </div>
            }
          </div>
        </div>
        <div>
          <div style={{ padding: '0 0 10px 20px' }}>
            <Typography variant="body2" color="primary">Created Variables</Typography>
          </div>
          <div style={{ display: 'flex', minHeight: '260px', flexWrap: 'wrap', margin: '0 20px 20px', gap: '20px', justifyContent: 'space-between' }}>
            <div
              style={{
                display: 'flex',
                backgroundColor: theme.palette.background.default,
                border: `1px solid ${theme.palette.grey[400]}`,
                flexGrow: '1',
                flexBasis: '800px',
                flexWrap: 'wrap',
              }}>
              <div
                style={{
                  maxWidth: '300px',
                  flexBasis: "300px",
                  flexGrow: '1',
                  padding: '0 10px',
                }}>
                <List>
                  {payloadVariables.map(v => (
                    <ListItem style={{ height: '20px', cursor: 'pointer', padding: '4px 10px' }} selected={v.name === selectedVariableName} onClick={() => setSelectedVariableName(v.name)}>
                      <ListItemIcon style={{ minWidth: '20px' }}>
                        <LabelImportantSharpIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>{v.name}</ListItemText>
                    </ListItem>

                  ))}
                </List>
              </div>
              <div style={{ display: 'flex', padding: '20px', flexGrow: '1', }}>
                {selectedVariableName &&
                  <div
                    style={{
                      flexGrow: '1',
                      height: '100%',
                      border: `1px solid ${theme.palette.grey[400]}`,
                      backgroundColor: theme.palette.grey[200],
                      borderRadius: '8px',
                      padding: '10px 20px',
                      overflow: 'hidden',
                    }}>
                    <Typography variant="overline">Example:</Typography>
                    <Divider sx={{ marginBottom: '10px' }} />
                    <ExamplePayloadContent variable={payloadVariables.find(v => v.name === selectedVariableName)!} />
                  </div>}
              </div>
            </div>
            <div style={{ flexGrow: '1000', display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
              <Button color="primary" variant="contained">Next</Button>
            </div>
          </div>
        </div>

      </div>
    </Container>
  )
}
