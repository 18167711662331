import {
  Breadcrumbs,
  Chip,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { PayloadItem } from "../../models/PayloadItem";
import { PayloadVariable } from "../../models/PayloadVariable";
import { ExamplePayloadContent } from "../ExamplePayloadContent";
import { useCardContext } from "../context/CardContext";
import { EventService } from "../../services/EventService";

type CustomCardProps = {
  dataItem: PayloadItem;
};

const CustomCard: React.FC<CustomCardProps> = ({ dataItem }) => {
  const { setPayloadVariables, payloadVariables } = useCardContext();
  const theme = useTheme()


  const [payloadVariable, setPayloadVariable] = useState<PayloadVariable>();

  useEffect(() => {
    const variable: PayloadVariable = {
      type: dataItem.type,
      typeAssigned: dataItem.type as any,
      name: dataItem.key,
      example: dataItem.example as any,
      itemType: (dataItem as any).itemType,
      accessor: [...dataItem.accessor],
    };
    setPayloadVariable(variable);
  }, [dataItem]);

  const transformPayload = (payloadVariables: any[]) => {
    return payloadVariables.map((item) => {
      const accessorArray = item.accessor;
      let modifiedAccessor = [];
      if (accessorArray.length === 1) {
        modifiedAccessor.push({
          type: accessorArray[0].type,
          key: item.name,
        });
      } else if (accessorArray.length >= 2) {
        modifiedAccessor.push({
          type: accessorArray[0].type,
          key: accessorArray[0].key,
        });
        modifiedAccessor.push({
          type: accessorArray[1].type,
          key: item.name,
        });
      }
      return {
        key: item.name,
        example: item.example,
        type: item.typeAssigned,
        accessor: modifiedAccessor,
      };
    });
  };

 

  useEffect(() => {
    const updateEvent = async () => {
      try {
        const transformedPayloadVariables = transformPayload(payloadVariables);
        const eventId = "";
        const response = await EventService.updateEvent(
          transformedPayloadVariables,
          eventId
        );
        if (response.success) {
          console.log("Successfully Updated");
        } else {
          console.log("Error");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    if (payloadVariables.length > 0) {
      updateEvent();
    }
  }, [payloadVariables]);
  
  
  

  // const createAccessor = (accessor: AccessorPart[], variableType: PayloadItem['type'], fn: string, data?: any): AccessorPart[] => {
  //   switch (variableType) {
  //     case "text":
  //     case "number":
  //       return accessor
  //     case "list":
  //     case "object":
  //       return [...accessor, { type: "object", function: fn, data }]
  //   }
  // }

  const addPayloadVariable = () => {
  
    if (payloadVariable) {
      let isUpdated = false;
      const updatedPayloadVariables = payloadVariables.map((v) => {
        if (
          v.name.trim().toLocaleLowerCase() ===
          payloadVariable.name.trim().toLocaleLowerCase()
        ) {
          isUpdated = true;
          return payloadVariable;
        } else {
          return v;
        }
      });
      if (isUpdated) {
        setPayloadVariables(updatedPayloadVariables);
      } else {
        setPayloadVariables([...payloadVariables, payloadVariable]);
      }
    }
  };


  if (!dataItem || !payloadVariable) {
    return <></>
  }

  return (
    <div className="payload-variable-picker" style={{ display: 'flex', gap: '20px', height: '100%' }}>
      <div style={{ flexBasis: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div>
          <Breadcrumbs separator={'\u{027A1}'} aria-label="breadcrumb" style={{ color: theme.palette.grey[700] }}>
            {payloadVariable.accessor.map(a => a.type === 'object_key' ? <span>{a.key}</span> : <Chip label={a.function} />)}
          </Breadcrumbs>
          <Divider sx={{ margin: '4px 0 10px' }} />
          <div className="input-group">
            <label>Type:</label>
            <select
              value={payloadVariable.typeAssigned || ""}
              onChange={(e) => setPayloadVariable({ ...payloadVariable, typeAssigned: e.target.value as any })}
            >
              <option value="text">Text</option>
              <option value="number">Number</option>
              <option value="list">List</option>
              <option value="object">Object</option>
              <option value="date">Date</option>
            </select>
          </div>
          <div className="input-group">
            <label>Name:</label>
            <input
              type="text"
              value={payloadVariable.name || ""}
              onChange={(e) => setPayloadVariable({ ...payloadVariable, name: e.target.value })}
            />
          </div>
        </div>
        <div>
          <button
            className="custom-button"
            onClick={addPayloadVariable}
            style={{ float: 'right' }}
          >
            Save
          </button>
        </div>
      </div>
      <Divider orientation="vertical" flexItem />
      <div className="variable-example"
        style={{
          flexBasis: '50%',
          border: `1px solid ${theme.palette.grey[200]}`,
          backgroundColor: theme.palette.grey[100],
          borderRadius: '8px',
          padding: '10px',
        }}>
        <Typography variant="overline">Example:</Typography>
        <Divider sx={{ marginBottom: '10px' }} />
        <ExamplePayloadContent variable={payloadVariable} />
      </div>
    </div>
  );
};

export default CustomCard;
