import React, { createContext, useContext, useState, ReactNode } from "react";
import { payLoadEnum } from "../enum/StepEnum";
import { PayloadVariable } from "../../models/PayloadVariable";
import { PayloadItem } from "../../models/PayloadItem";

interface CardContextProps {

  pastOrderTypeCardType: string;

  pastOrderPriceCardName: string;
  pastOrderPriceCardType: string;
  selectedPayLoadItem?: PayloadItem;
  payloadVariables: PayloadVariable[];

  payLoadData: string;

  setPastOrderTypeCardType: (type: string) => void;

  setPastOrderPriceCardName: (name: string) => void;
  setPastOrderPriceCardType: (type: string) => void;

  setPayloadVariables: (payloadVariables: PayloadVariable[]) => void;

  setPayLoadData: (item: any) => void;
  setSelectedPayLoadItem: (item: any) => void;
}

const CardContext = createContext<CardContextProps | undefined>(undefined);

export const CardProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
 

  const [pastOrderTypeCardType, setPastOrderTypeCardType] = useState("");

  const [pastOrderPriceCardName, setPastOrderPriceCardName] = useState("");
  const [pastOrderPriceCardType, setPastOrderPriceCardType] = useState("");

  const [payLoadData, setPayLoadData] = useState<string>(payLoadEnum.INITIAL_PAYLOAD);
  const [selectedPayLoadItem, setSelectedPayLoadItem] = useState<PayloadItem>();
  const [payloadVariables, setPayloadVariables] = useState<PayloadVariable[]>([]);

  return (
    <CardContext.Provider
      value={{
        pastOrderTypeCardType,
        setPastOrderTypeCardType,
        pastOrderPriceCardName,
        pastOrderPriceCardType,
        setPastOrderPriceCardName,
        setPastOrderPriceCardType,
        setPayLoadData,
        payLoadData,
        selectedPayLoadItem,
        setSelectedPayLoadItem,
        setPayloadVariables,
        payloadVariables,
      }}
    >
      {children}
    </CardContext.Provider>
  );
};

export const useCardContext = () => {
  const context = useContext(CardContext);
  if (!context) {
    throw new Error("useCardContext must be used within a CardProvider");
  }
  return context;
};
