import axios from "axios";
import { AppResponse } from "../models/Response";
import { Util } from "../util/Util";
import { PayloadVariable } from "../models/PayloadVariable";

export class EventService {
  public static async getEvent(eventId: string) {
    const ep = Util.apiPublicUrl(`getEventById/${eventId}`);
    const res = await axios.get<any, AppResponse<PayloadVariable>>(ep);
    return res;
  }


  public static async updateEvent(
    eventData: any,
    eventId: any
  ): Promise<AppResponse<PayloadVariable>> {
    const ep = Util.apiPublicUrl(`event/updateEvent/${eventId}`);
    const res = await axios.put<any, AppResponse<PayloadVariable>>(
      ep,
      eventData
    );
    return res;
  }
}
