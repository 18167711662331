export const StepEnum = {
  SELECT_CAMPAIGN_SETTINGS: "SELECT_CAMPAIGN_SETTINGS",
  CREATE_AD_GROUP: "CREATE_AD_GROUP",
  CREATE_AD: "CREATE_AD",
};

export const payLoadEnum = {
  INITIAL_PAYLOAD: "FIRST",
  SECOND_PAYLOAD: "SECOND",
  CREATE_AD: "CREATE_AD",
};
