import { useTheme } from "@mui/material";
import dayjs from 'dayjs';
import { prettyPrintJson } from 'pretty-print-json';
import React from "react";
import { PayloadVariable } from "../models/PayloadVariable";


const InnerHtml: React.FC<{ content: any }> = ({ content }) => {
  return <div dangerouslySetInnerHTML={{ __html: prettyPrintJson.toHtml(content) }}></div>
}

export const ExamplePayloadContent: React.FC<{ variable: PayloadVariable }> = ({ variable }) => {

  const theme = useTheme()

  switch (variable.type) {
    case 'number':
      return <>{variable.example}</>
    case 'text':
      if (variable.typeAssigned === 'date') {
        return <>{dayjs(variable.example).format('YYYY-MM-DD')}</>
      } else {
        return <>{variable.example}</>
      }
    case 'list':
      switch (variable.itemType) {
        case "number":
        case "text":
          return <ul>{variable.example.map((e: string | number) => <li>{e}</li>)}</ul>
        case "list":
          return (
            <ul>
              {variable.example.map((e: any[]) =>
                <li>
                  <InnerHtml content={e} />
                </li>)}
            </ul>)
        case "object":
          if (variable.example.length) {
            return (
              <table style={{ border: `1px solid ${theme.palette.grey[500]}`, backgroundColor: theme.palette.grey[200], borderCollapse: 'collapse' }}>
                <tr>
                  {Object.keys(variable.example[0]).map(k => (
                    <th style={{ border: `1px solid ${theme.palette.grey[400]}`, backgroundColor: theme.palette.grey[300], padding: '4px 10px' }}>{k}</th>))}
                </tr>
                {variable.example.map((e: Object) => (
                  <tr>
                    {Object.values(e).map(v => (
                      <td style={{ border: `1px solid ${theme.palette.grey[400]}`, padding: '4px 10px' }}>
                        <InnerHtml content={v} />
                      </td>
                    ))}
                  </tr>
                ))}
              </table>
            )
          } else {
            return <span>NO Data</span>
          }
        default:
          return <InnerHtml content={variable.example} />
      }
    case 'object':
      return (
        <table style={{ border: `1px solid ${theme.palette.grey[500]}`, backgroundColor: theme.palette.grey[200], borderCollapse: 'collapse' }}>
          {Object.entries(variable.example).map(([k, v]) => (
            <tr>
              <th style={{ border: `1px solid ${theme.palette.grey[400]}`, backgroundColor: theme.palette.grey[300], padding: '4px 10px' }}>{k}</th>
              <td style={{ border: `1px solid ${theme.palette.grey[400]}`, padding: '4px 10px' }}>
                <InnerHtml content={v} />
              </td>
            </tr>
          ))}
        </table>
      )
    default:
      return <InnerHtml content={(variable as any).example} />
  }
}
