import { AccessorPart } from "../models/AccessorPart";
import { PayloadItem } from "../models/PayloadItem";


export const transformPayloadToData = (payload: Object): PayloadItem[] => {
  console.log("transformPayloadToData  INPUT:", payload)
  const output = transform(payload, [])
  console.log("transformPayloadToData OUTPUT:", output)
  return output
}

const transform = (payload: Object, previousAccessor: AccessorPart[] = []): PayloadItem[] => {
  return Object.entries(payload).map(([key, value]) => {
    const accessorPart: AccessorPart = { type: 'object_key', key }
    const accessor: AccessorPart[] = [...previousAccessor, accessorPart]
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      return {
        key: key,
        example: value,
        type: 'object',
        keys: transform(value, accessor),
        accessor,
      };
    } else if (Array.isArray(value)) {
      return {
        key: key,
        example: value,
        type: 'list',
        itemType: 'object',
        itemKeys: transform(value[0], accessor),
        accessor,
      };
    } else {
      return {
        key: key,
        example: value,
        type: typeof value === 'number' ? 'number' : 'text',
        accessor,
      };
    }
  });
};
