import axios from "axios";
import { environment } from "../environment/environment";

export class Util {
  public static apiPublicUrl(path: string): string {
    return Util.getApiUrl() + "/api/" + path;
  }

  public static apiAuthUrl(path: string): string {
    return Util.getApiUrl() + "/api/auth/" + path;
  }

  public static initAxios(): void {
    axios.interceptors.request.clear();
    axios.interceptors.request.use((req) => {
      if (typeof localStorage !== "undefined") {
        req.headers.authorization = "Bearer " + localStorage.getItem("token");
      }
      return req;
    });

    axios.interceptors.response.clear();
    axios.interceptors.response.use(
      function (response) {
        return response?.data || null;
      },
      function (error) {
        return { success: false, data: undefined, error: error };
      }
    );
  }

  public static getApiUrl(): string {
    return environment.api_url;
  }
}

Util.initAxios();
